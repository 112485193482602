<!--特色美食-->
<template>
    <div class="bestway">
        <div class="bestway-main">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/xiangninghui'}">乡宁荟</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/xiangninghui/shopping'}">乡宁购</el-breadcrumb-item>
                    <el-breadcrumb-item >{{$route.query.type?'特色小吃':'特色美食'}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="way-title clearfix">
                <div class="fl"><router-link to="/productlist">特色产品</router-link></div>
                <div class="fl act"><router-link to="/foodlist">特色美食</router-link></div>
            </div>
            <div class="food-list w-1400">
                <!-- <div class="food-subtitle clearfix">
                    <div class="fl act">菜系分类：</div>
                    <div class="fl classifys">
                        <div class="fl" :class="{isactive:!params.filter.cuisine}" @click="params.filter.cuisine = '';getPagaList()">全部</div>
                        <ul class="clearfix fl search-list">
                            <li  v-for="(item) in caixi" class="fl classify-item" :key="item" :class="{isactive:params.filter.cuisine == item}"  @click="params.filter.cuisine = item;getPagaList()">{{item}}</li>
                        </ul>
                    </div>
                </div> -->
                <div v-if="pageData.list&&pageData.list.length>0">
                    <el-row :gutter="20">
                        <el-col :span="6" v-for="item in pageData.list" :key="item.id">
                            <router-link :to="`/fooddetail?id=${item.id}${$route.query.type?'&type=1':''}`">
                                <div class="food-item">
                                    <div class="food-pic">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls&&item.photoUrls.split(',')[0]"></el-image>
                                    </div>
                                    <div class="food-title ellipsis">{{item.name}}</div>
                                </div>
                            </router-link>
                            
                        </el-col>
                    </el-row>
                </div>
                
            </div>
           
        </div>
         <el-pagination class="pagination" :page-size="12" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
    </div>
</template>
<script>
import{GetFoodPage} from '@/api/home.js'
export default {
    data(){
        return{
            caixi:['川菜','粤菜','鲁菜','湘菜','闽菜','浙菜','苏菜','徽菜','楚菜','京菜'],
           params:{
               currentPage:1,
               pageSize:12,
               filter:{
                   name:"",
                   cuisine:""
               },
           },
           pageData:{},

        }
    },
    
    methods:{
        getPagaList(){
            GetFoodPage(this.params).then(res=>{
                this.pageData=res.data.data;
               
            })
        },
        handleCurrentChange(page){
            this.params.currentPage=page;
            this.getPagaList();
        }
    },
    mounted(){
        this.getPagaList();
    },
}

</script>
<style scoped>
.food-title{
    line-height: 50px;
    font-size: 18px;
    text-align: center;
    background: rgba(238, 238, 238, .7);
}
.food-item{
    margin-top: 20px;
}
.food-pic{
    height: 240px;
}
.classifys .isact{
    color:#fff;
    background: #e80050;
}
.classifys>span{
    margin:0 20px;
    padding:0 8px;
}
.food-subtitle{
    /* height: 42px; */
    line-height: 42px;
    font-size: 20px;
    border: 1px solid #777;
    font-weight: 500;
    padding: 0 6px;
}
.food-list{
    font-family: '微软雅黑';
}

.way-title div:last-child{
    border-left: 2px solid #141414;
}
.way-title div:hover,.way-title div.act{
    color: #0e6ac1;
}
.way-title .act a{
    color: #0e6ac1;  
}
.way-title div{
    padding: 0px 20px;
    cursor: pointer;
}
.way-title div:first-child{
    padding-left: 0;
}
.way-title{
    margin-top: 50px;
    padding: 15px 0;
    height: 50px;
    font-size: 20px;
    font-weight: 900;
    border-bottom: 2px solid #0e6ac1;
}
.bestway-main{
    width: 1200px;
    margin: 0 auto;
}
.search-list{
    max-width: 100%;
}
.classify-item{
    padding: 0px 20px;
    font-size: 18px;
    cursor: pointer;

}
.isactive{
    background-color:#e80050;
    cursor: pointer;
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>